import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import NavLink from 'components/nav-link'
import { useFooterItems } from 'utils/use-footer-items'
import { useSiteMetadata } from 'utils/use-site-metadata'

import './footer.scss'

const Authenticated = ({ author }) => {
  const navItems = useFooterItems()
  return (
    <Navbar id="footer" variant="dark" className="pb-1">
      <div className="container d-flex flex-column flex-lg-row justify-content-between align-items-center">
        <Nav className="d-none d-md-flex">
          {navItems.map((item, index) => (
            <NavLink key={index} href={item.to} className="text-nowrap">
              {item.label}
            </NavLink>
          ))}
        </Nav>
        <Navbar.Text className="text-nowrap">
          Copyright &copy; {new Date().getFullYear()}
          {author && <span className="d-none d-sm-inline">{' ' + author}</span>}
        </Navbar.Text>
      </div>
    </Navbar>
  )
}

const Basic = ({ author }) => {
  return (
    <Navbar id="footer" variant="dark" className="pb-1 justify-content-center">
      <Nav>
      <Navbar.Text className="text-nowrap text-quiet">
          Copyright &copy; {new Date().getFullYear()}
          {author && <span className="d-none d-sm-inline">{' ' + author}</span>}
        </Navbar.Text>
      </Nav>
    </Navbar>
  )
}
interface Props {
  author: string
  variant: string
}

const Footer = ({ author, variant }: Props) => {
  const { siteAuthor } = useSiteMetadata()
  const { isAuthenticated } = useAuth0()
  if (isAuthenticated) return <Authenticated author={author ?? siteAuthor} />
  if (variant === 'cover') return <Basic author={author ?? siteAuthor} />
  return null
}

export default Footer
