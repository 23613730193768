import React from 'react'
import { Link } from 'gatsby'

import Nav from 'react-bootstrap/Nav'

interface Props {
  children: React.ReactNode
  href: string
}

const NavLink = ({ children, href, ...otherProps }: Props) => {
  const isInternal = /^\/(?!\/)/.test(href)
  if (isInternal) {
    return (
      <Nav.Link as={Link} to={href} activeClassName="active" {...otherProps}>
        {children}
      </Nav.Link>
    )
  }
  return (
    <Nav.Link href={href} {...otherProps}>
      {children}
    </Nav.Link>
  )
}

export default NavLink