import React from 'react'
import { navigate } from 'gatsby'
import { useAuth0 } from '@auth0/auth0-react'

import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import NavLink from 'components/nav-link'
import { useHeaderItems } from 'utils/use-header-items'

import './header.scss'

const Authenticated = ({ location }) => {
  const { user, logout } = useAuth0()
  const navItems = useHeaderItems()
  const handleLogout = (e) => {
    e.preventDefault()
    logout({ returnTo: window.location.origin })
  }
  return (
    <Navbar
      id="header"
      collapseOnSelect
      fixed="top"
      expand="lg"
      variant="dark"
      className="px-1 px-md-3 justify-content-between transparent"
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto" activeKey={location?.pathname}>
          {navItems.map((item, index) => (
            <NavLink key={index} href={item.to}>
              {item.label}
            </NavLink>
          ))}
          <NavLink className="d-lg-none" href="logout" onClick={handleLogout}>
            Log out
          </NavLink>
        </Nav>
        <Nav className="d-none d-lg-flex">
          <Navbar.Text className="text-muted text-nowrap me-3">Logged in: {user.name}</Navbar.Text>
          <Button variant="outline-light" size="sm" onClick={handleLogout}>
            Log out
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

const Basic = ({ location }) => {
  const { pathname } = location
  return (
    <Navbar id="header" fixed="top" variant="dark" className="px-1 px-md-3 justify-content-center">
      <Nav>
        <NavLink href="/" className="text-muted">
          Portal
        </NavLink>
        {pathname !== '/node/' && (
          <NavLink href="/node/" className="text-muted">
            Nodes
          </NavLink>
        )}
      </Nav>
    </Navbar>
  )
}

const Login = ({ location }) => {
  const { loginWithRedirect } = useAuth0()
  const handleJoin = (e) => {
    e.preventDefault()
    navigate('/signup/')
  }
  const handleLogin = (e) => {
    e.preventDefault()
    loginWithRedirect()
  }

  return (
    <Navbar id="header" fixed="top" variant="dark" className="px-1 px-md-3 justify-content-center">
      <Nav>
        <Button variant="outline-light" onClick={handleJoin}>
          Join YOMO
        </Button>
      </Nav>
    </Navbar>
  )
}

interface Props {
  location: Location
  variant: string
}

const Header = ({ location, variant }: Props) => {
  const { isAuthenticated } = useAuth0()
  if (isAuthenticated) return <Authenticated location={location} />
  if (variant === 'cover') return null
  if (variant === 'login') return <Login location={location} />
  return <Basic location={location} />
}

export default Header
