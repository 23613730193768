import { graphql, useStaticQuery } from 'gatsby'

export const useHeaderItems = () => {
  const { items } = useStaticQuery(
    graphql`
      query HeaderItemsYamlQuery {
        items: allHeaderItemsYaml {
          nodes {
            label
            to
          }
        }
      }
    `
  )

  return items.nodes
}
