import { graphql, useStaticQuery } from 'gatsby'

export const useFooterItems = () => {
  const { items } = useStaticQuery(
    graphql`
      query FooterItemsYamlQuery {
        items: allFooterItemsYaml {
          nodes {
            label
            to
          }
        }
      }
    `
  )

  return items.nodes
}
